.main {
  padding: 300px 100px;
  background-color:#141628;
  color: white;
}

@media  screen and (max-width: 992px) {
  .main {
    padding: 230px 50px;
  }

}